.dts {
	left: 45%;
}

.blue__section {
	position: relative;
}

.how {
	position: relative;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		background-image: url('../../dist//img/mobile-trusted.png');
		width: 100%;
		height: 100%;
		z-index: -1;
		background-position: center;
		background-size: cover;
	}
	h2 {
		margin-bottom: 1.5em;
	}

	&__icon {
		display: flex;
		margin-bottom: 3.5em;
	}
	&__icon-main {
		position: relative;
	}
	img {
		position: absolute;
		bottom: -25px;
		right: 50%;
		transform: translate(50%, 50%) rotate(90deg) scale(1.8);
	}
	&__desc {
		max-width: 200px;
		margin-left: 2em;
		line-height: 27px;
		font-size: 15px;
	}
	&__heading {
		margin-bottom: 0.5em;
		transition: color 0.3s;
	}

	.icon__circle {
		width: 90px;
		height: 90px;
		position: relative;
		background-color: #0085ff;
		border-radius: 50%;
		cursor: pointer;
		transition: background-color 0.3s;
		svg {
			pointer-events: none;
		}
		path {
			pointer-events: none;
		}
	}

	.second {
		background-color: #fff;
		&:hover {
			background-color: #0085ff;
			path {
				fill: #fff;
			}
		}
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	path {
		transition: fill 0.3s;
	}
	&__heading {
		font-weight: bold;
	}
	&__img {
		display: none;
	}
}

@media (min-width: 768px) {
	
	.how {
		&__content {
			max-width: 1200px;
		}
		&__heading {
			margin-top: 1.3em;
			margin-bottom: 0.5em;
		}
		&__icons {
			display: flex;
			flex-wrap: wrap;
		}
		&__icon {
			display: flex;
			flex-direction: column;
			margin-left: 0;
			width: 16.6%;
			max-width: 250px;
		}
		&__desc {
			margin-left: 0;
			font-size: 14px;
			max-width: 120px;
		}
		img {
			top: 50%;
			left: 85%;
			transform: translate(-50%, -50%) rotate(0deg) scale(1);
		}
	}
	.how .icon__circle {
		width: 90px;
		height: 90px;
		position: relative;
		background-color: #0085ff;
		border-radius: 50%;
		cursor: pointer;
		transition: background-color 0.3s;
	}
	.how img {
		left: 70%;
		transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
	}
	.how__icon {
		width: 16.6%;
		max-width: 250px;
	}
}

@media (min-width: 992px) {
	.how {
		&__bg {
			background-image: url('../../dist/img/trusted-desktop.png');
			background-position: center;
		}
		&__img {
			display: block;
			position: relative;

			img {
				position: static;
				transform: none;
			}
		}
		.img-container {
			position: absolute;
			left: 50%;
			top: -260px;
		}
		.small {
			position: absolute;
			right: -10%;
			bottom: 0%;
			top: initial;
			left: initial;
		}
		.dotty {
			top: initial;
			left: initial;
			position: absolute;
			bottom: 40px;
			left: -50px;
		}
		img {
			left: 75%;
			transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
		}
		&__img {
			transform: none !important;
			img {
				transform: none !important;
			}
		}
		&__desc {
			max-width: 160px;
		}
	}
}
@media (min-width: 768px) {
	.how__heading {
		margin-top: 1.3em;
		margin-bottom: 1em;
		font-weight: bold;
	}
	.how h2 {
		margin-bottom: 2.5em;
	}
}
.form-message {
	display: none;
}
