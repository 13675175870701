*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

@font-face {
	font-family: 'boldbold';
	src: url('../../fonts/masifard-bold-webfont.woff2') format('woff2'),
		url('../../fonts/masifard-bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'regularregular';
	src: url('../../fonts/masifard-regular-webfont.woff2') format('woff2'),
		url('../../fonts/masifard-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html,
body {
	height: 100%;
	font-size: 1.7rem;
	font-family: 'regularregular', sans-serif;
}
h1,
h2,
h3 {
	font-family: 'boldbold';
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	background-color: #073683;
	color: #fff;
	overflow-x: hidden;
}
a {
	text-decoration: none;
	color: rgb(0, 0, 0);
}

input,
button,
textarea,
select {
	font-family: inherit;
}

button {
	border: none;
	cursor: pointer;
	white-space: nowrap !important;
}
ul {
	list-style: none;
}





@keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
