.index-de {
	.stack {
		&__subsection {
			width: 100%;
		}
	}
}
@media (max-width: 678px) {
	.index-de {
		.stack.section-padding {
			padding-right: 0;
		}
	}
}
.stack {
	background-color: #00173a;

	h2 {
		margin-bottom: 1.3em;
	}
	&__heading {
		font-size: 2rem;
		color: #0085ff;
		margin-bottom: 1em;
		font-weight: bold;
		max-width: 150px;
	}
	&__subsection {
		margin-bottom: 2em;
		width: 50%;
	}
	.all-sections {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__icon {
		width: 8px;
		height: 8px;
		background-color: #fff;
		border-radius: 50%;
		margin-right: 0.8em;
	}
	.cyan-icon {
		background-color: #94cdb4;
	}
	.red-icon {
		background-color: #e52528;
	}
	.purple-icon {
		background-color: #7e4d99;
	}
	.orange-icon {
		background-color: #f18918;
	}
	&__element {
		display: flex;
		align-items: center;
		margin-bottom: 1.3em;
	}
}
@media (min-width: 420px) {
	.index-de {
		.stack {
			&__subsection {
				width: 50%;
			}
		}
	}
}
@media (min-width: 576px) {
	.index-de {
		.stack {
			&__subsection {
				width: 33%;
			}
		}
	}
}
@media (min-width: 992px) {
	.index-de {
		.stack {
			&__subsection {
				width: max-content;
			}
		}
	}
}
@media (min-width: 576px) {
	.stack {
		&__subsection {
			width: 33%;
		}
	}
}
@media (min-width: 678px) {
	.orange-dot {
		display: none;
	}
}

@media (min-width: 992px) {
	.stack {
		&__subsection {
			width: max-content;
		}
		&__heading {
			max-width: 220px;
		}
	}
	.stack__heading {
		font-size: 2rem;
		color: #0085ff;
		margin-bottom: 2em;
		font-weight: 700;
	}
	.glide {
		margin-bottom: 16em;
	}
	.stack h2 {
		margin-bottom: 1.3em;
		padding-top: 30px;
	}
}
.stack__heading {
	font-size: 1.6rem;
	color: #0085ff;
	margin-bottom: 2em;
	font-weight: 700;
	max-width: 300px;
}

.stack__element {
	display: flex;
	align-items: center;
	margin-bottom: 1.7em;
	font-size: 15px;
}
