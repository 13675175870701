@media (min-width: 992px) {
	.tas {
		.software__centered {
			text-align: left !important;
		}
	}
	.tas {
		.software-sec {
			&__texts {
				justify-content: flex-start;
				line-height: 27px;
			}
			&__text {
				width: 50%;
				max-width: 520px;
				&:first-of-type {
					margin-right: 15%;
				}
			}
		}
	}
	.tas {
		.desktop__bg {
			background-image: url('../../dist/img/dots/taas-bg.jpg');
			left: -1px;
		}
	}
	.tas {
		.desktop__dots-services {
			display: block;
			img {
				&:first-of-type {
					position: absolute;
					right: 48%;
					top: 65vh;
				}
				&:nth-of-type(2) {
					position: absolute;
					right: 50%;
					top: 35%;
				}
				&:nth-of-type(3) {
					position: absolute;
					right: 20%;
					top: 55%;
				}
			}
		}
	}
	.tas {
		.desktop__dots-services {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: -1;
		}
		.desktop__bg {
			height: 130vh;
		}
		.software__centered {
			margin-top: 3em;
			margin-bottom: 11em;
			line-height: 27px;
		}
		.how {
			margin-bottom: 4em;
		}
		.services__service {
			margin-bottom: 5em;
		}
		.software-sec__texts {
			line-height: 27px;
		}
	}
}
main {
	.contact.section-padding {
		padding-top: 0;
	}
}
main {
	.contact:before {
		top: 0;
	}
}
.sft-link {
	font-weight: bold;
	color: #0085ff;
}
@media (min-width:1400px){
	.tas {
		.desktop__dots-services {
			display: block;
			img {
				&:first-of-type {
					position: absolute;
					right: 48%;
					top: 55vh;
				}
				&:nth-of-type(2) {
					position: absolute;
					right: 50%;
					top: 35%;
				}
				&:nth-of-type(3) {
					position: absolute;
					right: 20%;
					top: 55%;
				}
			}
		}
	}
}