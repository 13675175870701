.services {
	position: relative;
	&-blue-mobile {
		position: absolute;
		right: 30%;
		top: 60px;
	}
	
	&__icon {
		margin-top: 0.7em;
		margin-bottom: 0.7em;
	}
	&__blue {
		color: #0085ff;
		font-weight: bold;
		font-size: 1.75rem;
		margin-bottom: 0.9em;
	}
	&__desc {
		font-size: 15px;
		line-height: 28px;
		margin-bottom: 1.2em;
	}
	&__list {
		margin-bottom: 0.5em;
	}
	&__list-item {
		display: flex;
		align-items: center;
		margin-bottom: 0.8em;
		line-height: 27px;
		p {
			margin-left: 1em;
			font-size: 15px;
		}
	}
	.btn {
		align-self: flex-end;
	}
	&__btn {
		color: #fff;
		padding: 0.4em 0.8em;
		border-radius: 20px;
		background-color: #0085ff;
		align-self: flex-end;
	}
	&__service {
		margin-bottom: 2em;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 450px;
	}

	&__figure {
		position: relative;
		margin-left: 30%;
		display: none;
		width: 256px;
		height: 256px;
	}

	&__figure-orange-dot {
		position: absolute;
		left: 3%;
		top: 10%;
	}
	&__figure-dark-dot {
		position: absolute;
		right: -15%;
		top: -5%;
	}
	&__figure-small-dot {
		position: absolute;
		right: -7%;
		bottom: 3%;
	}
}

@media (min-width: 992px) {
	.services.section-padding {
		padding-top: 0.7em;
	}

	.services {
		&__btn {
			padding: 0.6em 0.8em;
		}
		&__blue {
			margin-bottom: 1em;
		}
		&__heading {
			margin-top: -1em;
			font-size: 3.5rem;
			margin-bottom: 0.5em;
		}
		&__props {
			display: flex;
			flex-wrap: wrap;
		}
		&__list-item {
			margin-bottom: 0.9em;
		}
		&__service {
			display: flex;
			max-width: 510px;
			&:nth-of-type(2) {
				margin-left: 5vw;
			}
		}

		&__icon {
			margin: 1em 0;
		}

		.btn {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			align-self: flex-end;
		}
		&-blue-mobile {
			display: none;
		}
		&__figure {
			display: block;
		}
		&__figure-light-dot {
			position: absolute;
			right: -100%;
			top: 10%;
		}
	}

	#services {
		h2 {
			margin-top: -1em;
			font-size: 4.2rem;
			margin-bottom: 0.5em;
		}
	}
	.services__heading {
		font-size: 4.2rem;
		margin-bottom: 0.5em;
	}
	.services__blue {
		margin-bottom: 2em;
	}
	.services__list-item {
		margin-bottom: 0.9em;
	}
	.services__desc {
		line-height: 27px;
		margin-bottom: 2.5em;
	}
}

@media (min-width: 1200px) {
	.services {
		&__service {
			&:nth-of-type(2) {
				margin-left: 10vw;
			}
		}
	}
	.glide {
		margin-bottom: 21em;
	}
	.how {
		h2 {
			margin-top: -1.5em;
		}
		.img-container {
			position: absolute;
			left: 50%;
			top: -320px;
		}
		&__content {
			max-width: 1400px;
		}
		&__icon {
			width: 16.6%;
			max-width: 250px;
		}
		.third {
			display: block;
		}
		&__desc {
			max-width: 150px;
			font-size: 15px !important;
		}
	}
	.services__heading {
		margin-top: -1em;
		font-size: 4.2rem;
		margin-bottom: 0.5em;
	}
}

@media (max-width: 992px) {
	.services.section-padding {
		padding-top: 5em;
	}
}
@media (min-width: 992px) {
	.services__list-item {
		margin-bottom: 0.9em;
		font-size: 15px;
	}
}

.services__desc {
	margin-bottom: 1.2em;
	font-size: 15px;
}
