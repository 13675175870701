@use 'reset' as *;
@use 'components' as *;
@use 'contact' as *;
@use 'dev' as *;
@use 'footer' as *;
@use 'header' as *;
@use 'nav' as *;
@use 'how' as *;
@use 'services' as *;
@use 'stack' as *;
@use 'standards' as *;
@use 'tas' as *;
@use 'trusted' as *;

.grecaptcha-badge {
	visibility: hidden !important;
}

.deutsch-heading {
	font-size: 3.6rem !important;
}

@media (min-width: 1080px) {
	.deutsch-heading {
		font-size: 6.5rem !important;
	}
	.stack.section-padding {
		padding-top: 5em;
	}

	.standards__item {
		position: relative;
	}

	.desktop__dot {
		display: block;
		position: absolute;
	}
	.desktop__red {
		left: -15%;
		top: 20%;
	}
	.desktop__purple {
		left: -20%;
		top: 70%;
	}
	.desktop__orange-second {
		right: -35%;
		top: -17%;
	}
	.desktop__green {
		right: -70%;
		top: 0%;
	}

	.desktop__orange {
		top: 80%;
		right: 20%;
	}

	.trusted.section-padding {
		padding-top: 5em;
	}
	#standards h2 {
		margin-bottom: 2em;
	}
	.how.section-padding {
		padding-bottom: 8em;
	}
	.standards {
		position: relative;
		.second-row {
			.standards__item {
				&:nth-of-type(1) {
					margin-left: 7%;
				}
			}
		}
		&__descr {
			line-height: 31px;
		}
		&__content {
			display: none;
		}
		&__desktop {
			overflow-x: hidden;
			display: flex;
			flex-direction: column;
		}
		&__heading {
			margin-top: 1.3em;
		}
		&__row {
			display: flex;
			margin-bottom: 2.5em;
		}
		&__item {
			flex-direction: column;
			width: 33%;
		}
		&__desc {
			margin-left: 0;
			max-width: 300px;
		}
	}
	.desktop__img {
		display: block;
		position: absolute;
		right: 22%;
		top: -17%;
	}
	.dot-2 {
		position: absolute;
		left: 3%;
		top: 12%;
	}
}

.footer {
	background-color: #00173a;
	padding: 2em;
}
.foooter__p {
	color: #0085ff;
	font-weight: bold;
}

@media (min-width: 1400px) {
	.desktop__img {
		right: 27%;
	}
}
@media (min-width: 2000px) {
	.desktop__img {
		right: 30%;
	}
}
@media (min-width: 2800px) {
	.desktop__img {
		right: 33%;
	}
}
@media (min-width: 3500px) {
	.desktop__img {
		right: 39%;
	}
}

@media (min-width: 992px) {
	.services__blue {
		margin-bottom: 2em;
	}
	.services__list-item {
		margin-bottom: 0.5em;
	}
}
.services__desc {
	line-height: 27px;
	margin-bottom: 2.5em;
}
.services__btn {
	color: #fff;
	padding: 0.6em 1em;
	border-radius: 20px;
	background-color: #0085ff;
	font-size: 15px;
}
.header__btn {
	color: #fff;
	background-color: #0085ff;
	padding: 0.68em 1.4em;
	border-radius: 20px;
	font-size: 1.43rem;
	z-index: 3;
	max-width: none;
	transition: transform 0.3s;
	display: inline-block;
}

.services__btn {
	color: #fff;
	padding: 0.68em 1.4em;
	border-radius: 20px;
	background-color: #0085ff;
	font-size: 1.43rem;
}
.contact__btn {
	padding: 0.63em 1.4em;
	font-size: 1.42rem;
	background-color: #0076e8;
	border-radius: 20px;
	color: #fff;
	margin-bottom: 1.5em;
	border: none;
	white-space: nowrap;
	transition: transform 0.3s;
}
.contact__btn {
	position: relative;
}
