*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "boldbold";
  src: url("../../fonts/masifard-bold-webfont.woff2") format("woff2"), url("../../fonts/masifard-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "regularregular";
  src: url("../../fonts/masifard-regular-webfont.woff2") format("woff2"), url("../../fonts/masifard-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  height: 100%;
  font-size: 1.7rem;
  font-family: "regularregular", sans-serif;
}

h1,
h2,
h3 {
  font-family: "boldbold";
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #073683;
  color: #fff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

input,
button,
textarea,
select {
  font-family: inherit;
}

button {
  border: none;
  cursor: pointer;
  white-space: nowrap !important;
}

ul {
  list-style: none;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fixed-page {
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
}

.burger-btn {
  padding: 0.5em;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0;
  position: relative;
}
.burger-btn__box {
  width: 45px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.burger-btn-line {
  height: 4px;
  width: 100%;
  background-color: #ffffff;
  transition: transform 0.3s, opacity 0.2s;
}

.opened.burger-btn-line--1 {
  transform: rotate(45deg) translate(9px, 10px);
}

.opened.burger-btn-line--2 {
  opacity: 0;
}

.opened.burger-btn-line--3 {
  transform: rotate(-45deg) translate(9px, -10px);
}

.section-padding {
  padding: 4em 1em;
}

.header.section-padding {
  padding-bottom: 0;
}

.services.section-padding {
  padding-top: 2em;
}

.desktop__dots-services {
  display: none;
}

.services__heading {
  font-size: 2.7rem;
}

.glide {
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.glide__arrow--left {
  background-image: url("../../dist/img/icons/arrow-left.png");
  background-color: transparent;
  display: flex;
  position: absolute;
  left: -9%;
  top: 45%;
  background-size: cover;
  width: 15px;
  height: 22px;
  background-repeat: no-repeat;
}
.glide__arrow--left::after {
  content: none;
}

.glide__arrow--right {
  background-image: url("../../dist/img/icons/arrow-right.svg");
  display: flex;
  background-size: cover;
  width: 15px;
  background-color: transparent;
  height: 22px;
  position: absolute;
  right: -6%;
  top: 45%;
  background-repeat: no-repeat;
}

.hovered {
  background-color: #fff !important;
}
.hovered path {
  fill: #0085ff !important;
}

.howered-heading {
  color: #0085ff !important;
}

@media (min-width: 700px) {
  .glide {
    max-width: 600px;
  }
}
@media (min-width: 1024px) {
  .glide {
    max-width: 900px;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact__btn {
  position: relative;
  min-height: 42px;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -15px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 26px;
  height: 26px;
  border: solid 4px transparent;
  border-top-color: #ffffff !important;
  border-left-color: #ffffff !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.standards__desktop {
  display: none;
}

.desktop__dot {
  display: none;
}

.desktop__img {
  display: none;
}

.contact__icon-desktop {
  display: none;
}

.contact.section-padding {
  padding-top: 4.5em;
}

.contact {
  position: relative;
}
.contact a {
  color: #fff;
}
.contact .tel {
  text-decoration: underline;
}
.contact .mail {
  text-decoration: underline;
  margin-bottom: 0.5em;
}
.contact__icon-mobile {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}
.contact h2 {
  margin-bottom: 1em;
}
.contact__desc {
  line-height: 30px;
}
.contact__icons {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.contact__icons img {
  margin-right: 0.25em;
  width: 32px;
}

.contact__div {
  position: relative;
  min-height: 38px;
  min-width: 200px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}
.contact__input {
  width: 90%;
  font-size: 1.5rem;
  height: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  padding: 0.5em 1em;
  z-index: 1;
  max-width: 400px;
}
.contact__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.contact .textarea {
  min-height: 140px;
  line-height: 25px;
  resize: vertical;
  width: 100%;
}
.contact__dots {
  display: none;
}
.contact__label {
  position: absolute;
  left: 1rem;
  z-index: 23;
  top: 1rem;
  font-size: 1.5rem;
  padding: 0 0.25rem;
  background-color: #ffffff;
  color: #000000;
  transition: top 0.3s, left 0.3s, font-size 0.3s;
}
.contact__btn {
  padding: 0.8em 0.1em;
  font-size: 1.5rem;
  background-color: #0076e8;
  border-radius: 20px;
  color: #fff;
  width: 80%;
  max-width: max-content;
  margin-bottom: 1.5em;
  cursor: pointer;
  border: none;
  transition: transform 0.3s;
}
.contact__btn:hover {
  transform: scale(0.98);
}

.lang-visible {
  display: flex !important;
}

@media (min-width: 992px) {
  .contact.section-padding {
    padding-top: 7.5em;
  }
  .contact {
    position: relative;
  }
  .contact::before {
    content: "";
    position: absolute;
    top: 85px;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-image: url("../../dist/img/contact-bg.png");
    background-size: cover;
    background-position: center;
    background-size: 100%, auto, cover;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: -1;
  }
  .contact .wrapper {
    background-size: cover;
    background-position: center;
  }
  .contact__icon-mobile {
    display: none;
  }
  .contact__icon-desktop {
    display: block;
    position: absolute;
    left: 50%;
    top: 15%;
  }
  .contact__desc {
    margin-bottom: 1.5em;
  }
  .contact__icons {
    margin-bottom: 2em;
  }
  .contact__icons img {
    margin-right: 0.4em;
  }
  .contact__dots {
    display: block;
  }
  .contact .orange-contact {
    position: absolute;
    top: 70%;
    right: 20%;
    z-index: 2;
  }
  .contact .contact__dots {
    display: block;
  }
  .contact .contact__desktop .dot {
    width: 5px;
    position: absolute;
    height: 5px;
    z-index: -2;
    border-radius: 50%;
    animation: blink 3s infinite forwards;
  }
  .contact .contact-red {
    background-color: #e52528;
    top: 25%;
    right: 20%;
    z-index: 3;
    animation-delay: 0.9s !important;
  }
  .contact .contact-cyan {
    background-color: #94cdb4;
    top: 65%;
    left: 50%;
    animation-delay: 0.7s !important;
  }
  .contact .contact-purple {
    background-color: #7e4d99;
    right: 14%;
    top: 50%;
    animation-delay: 0.1s !important;
  }
  .contact .contact-orange {
    background-color: #f18918;
    top: 80%;
    left: 34%;
    animation-delay: 0.2s !important;
  }
  .contact .contact-orange-second {
    background-color: #f18918;
    top: 15%;
    left: 40%;
    animation-delay: 0.5s !important;
  }
  .contact h2 {
    padding-top: 1.5em;
  }
}
.contact__desc {
  line-height: 30px;
  font-size: 15px;
}

@media (min-width: 1600px) {
  .contact .contact-red {
    top: 30%;
  }
}
.has-error {
  border-color: #ed1c24;
}

.error-div {
  width: 90%;
  background: #ed1c24;
  color: #fff;
  font-size: 14px;
  padding: 7px;
  margin-top: 0;
  margin-bottom: 0.5em;
  max-width: 400px;
  border-radius: 5px;
}

.contact__div.textarea .error-div {
  width: 100%;
}

.contact__btn[disabled] {
  background-color: rgba(226, 226, 226, 0.5);
  cursor: not-allowed;
}

.contact__btn {
  cursor: pointer;
}

.error-hidden {
  display: none;
}

.error-visible {
  display: block;
}

main .software-sec.section-padding {
  padding-bottom: 2em;
}
main h1 {
  font-size: 6.5rem !important;
}

.sft .contact.section-padding {
  padding-top: 0;
}

.software-sec {
  margin-top: 4em;
}
.software-sec .header__btn {
  margin-top: 1.5em;
}
.software-sec .how__img {
  display: none;
}
.software-sec .contact.section-padding {
  padding-top: 0;
}
.software-sec .how.section-padding {
  padding-left: 0em;
  padding-right: 0em;
}
.software-sec .services__service {
  margin-left: 0 !important;
  margin-bottom: 3em;
}
.software-sec h1 {
  font-size: 4rem;
  margin-bottom: 0.1em;
}
.software-sec h2 {
  color: #0085ff;
  margin-bottom: 0.5em;
}
.software-sec .services__blue {
  font-size: 1.8rem;
}
.software-sec .services__icon {
  margin-bottom: 1em;
}
.software-sec__heading {
  color: #0085ff;
  margin-bottom: 1em;
  font-size: 1.6rem;
  font-weight: bold;
}
.software-sec__texts {
  display: flex;
  font-size: 15px;
  flex-direction: column;
  line-height: 26px;
}
.software-sec__text {
  margin-bottom: 2.5em;
}
.software-sec .software__centered-heading {
  color: #0085ff;
  margin-bottom: 1.3em;
  font-size: 1.6rem;
  font-weight: bold;
}
.software-sec .software__centered-desc {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 4em;
}
.software-sec .how h2 {
  color: #fff;
}

@media (min-width: 992px) {
  .dotters {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .sft .contact::before {
    top: 0;
  }
  .dev .desktop__dots-services.dsk {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: block;
  }
  .dev .desktop__dots-services.dsk img:first-of-type {
    position: absolute;
    right: 10%;
    top: 80%;
  }
  .dev .desktop__dots-services.dsk img:nth-of-type(2) {
    position: absolute;
    right: 25%;
    top: 45%;
  }
  .dev .desktop__dots-services.dsk img:nth-of-type(3) {
    position: absolute;
    right: 45%;
    top: 80%;
  }
  .desktop__bg {
    background-image: url("../../dist/img/soft-bg.jpg");
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130vh;
    z-index: -2;
  }
  .software-sec h1 {
    font-size: 5.5rem;
    margin-bottom: 0.05em;
  }
  .software-sec h2 {
    font-size: 3.5rem;
    margin-bottom: 0.8em;
  }
  .software-sec__desc {
    font-size: 1.65rem;
  }
  .software-sec__texts {
    margin-top: 4em;
    flex-direction: row;
    justify-content: space-between;
  }
  .software-sec__text {
    width: 33%;
    max-width: 400px;
    margin-right: 2em;
  }
  .software-sec__text:last-of-type {
    margin-right: 0;
  }
  .software-sec .software__centered {
    margin-top: 2em;
    text-align: center;
    margin-bottom: 7em !important;
  }
  .software-sec .how {
    margin-bottom: 2em;
  }
  .software-sec .how h2 {
    margin-bottom: 2em;
  }
  .dev .software__centered {
    text-align: center !important;
    margin-top: 4em;
    margin-bottom: 11em;
    line-height: 27px;
  }
  .dev .how {
    margin-bottom: 4em;
  }
  .dev .services__service {
    margin-bottom: 5em;
  }
  .dev .software-sec__texts {
    line-height: 27px;
  }
}
.form-message {
  background-color: #0085ff;
  max-width: 400px;
  line-height: 27px;
  border-radius: 9px;
  padding: 10px;
  font-size: 15px;
}

.form-message.error-d {
  background-color: #ed1c24;
}

.footer {
  padding-left: 1em !important;
  font-size: 15px;
}

@media (min-width: 992px) {
  .footer {
    padding: 2.5em 3em !important;
    padding-left: 2.2em !important;
  }
}
.header__mobile-bg {
  position: absolute;
  top: 60px;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: url("../../dist/img/header-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: -1;
}
.header__items {
  margin-top: 80px;
}
.header__heading {
  max-width: 450px;
  font-size: 3.1rem;
  line-height: 40px;
  margin-bottom: 0.7em;
}
.header__subtitle {
  font-size: 1.5rem;
  line-height: 25px;
  margin-bottom: 2em;
  max-width: 500px;
}
.header__btn {
  color: #fff;
  background-color: #0085ff;
  padding: 0.6em 1.3em;
  border-radius: 20px;
  font-size: 1.6rem;
  z-index: 3;
  transition: transform 0.3s;
  display: inline-block;
}
.header__btn:hover {
  transform: scale(0.98);
}
.header .bold {
  margin-top: 0.5em;
  font-size: 3.5rem;
  margin-bottom: 0.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.header__stats {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}
.header__stats-stat {
  margin: 2em 1.5em;
  width: 100%;
  min-width: 165px;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header .desc {
  font-size: 1.6rem;
}
.header__icon {
  display: none;
}
.header .orange-dot {
  position: absolute;
  left: -5%;
  bottom: 30%;
}
.header__mobile-dots .purple-dot {
  background-color: #7e4d99;
  left: 30%;
  top: 100px;
}
.header__mobile-dots .red-dot {
  background-color: #e52528;
  top: 120px;
  right: 30%;
}
.header__mobile-dots .dark-purple-dot {
  background-color: #7e4d99;
  right: 10%;
  top: 290px;
}
.header__mobile-dots .cyan-dot {
  background-color: #94cdb4;
  right: 25%;
  top: 280px;
}
.header__mobile-dots .second-red-dot {
  background-color: #e52528;
  left: 15%;
  top: 270px;
}
.header__mobile-dots .orange-second-dot {
  background-color: #f18918;
  top: 430px;
  right: 45%;
}
.header__mobile-dots .dot {
  width: 5px;
  position: absolute;
  height: 5px;
  z-index: -2;
  border-radius: 50%;
  animation: blink 3s infinite forwards;
}
.header__mobile-dots .dot:nth-of-type(1) {
  animation-delay: 0.4s;
}
.header__mobile-dots .dot:nth-of-type(2) {
  animation-delay: 0.6s;
}
.header__mobile-dots .dot:nth-of-type(3) {
  animation-delay: 0.3s;
}
.header__mobile-dots .dot:nth-of-type(4) {
  animation-delay: 0.7s;
}
.header__mobile-dots .dot:nth-of-type(5) {
  animation-delay: 0.6s;
}
.header__mobile-dots .dot:nth-of-type(6) {
  animation-delay: 0.5s;
}
.header__mobile-dots .dot:nth-of-type(7) {
  animation-delay: 0.9s;
}
.header__mobile-dots .dot:nth-of-type(9) {
  animation-delay: 0.4s;
}
.header__mobile-dots .dot:nth-of-type(9) {
  animation-delay: 0.5s;
}
.header__mobile-dots .dot:nth-of-type(10) {
  animation-delay: 0.9s;
}
.header__mobile-dots .dot:nth-of-type(10) {
  animation-delay: 0.7s;
}

@media (min-width: 992px) {
  .header {
    min-height: 100vh;
  }
  .header .header__heading {
    font-size: 4rem;
    max-width: 580px;
    line-height: 1;
    margin-top: 110px;
  }
  .header__mobile-bg {
    background-image: url("../../dist/img/header-big.png");
    top: 90px;
  }
  .header__icon {
    display: block;
    position: absolute;
    top: 25%;
    right: 0;
  }
  .header__stats {
    margin-top: 0em;
  }
  .header__stats-stat {
    margin: 4em;
    margin-bottom: 4em;
  }
  .header__mobile-dots .cyan-desktop {
    background-color: #94cdb4;
    right: 43%;
    top: 380px;
  }
  .header__mobile-dots .purple-desktop {
    background-color: #7e4d99;
    top: 80%;
    left: 25%;
  }
  .header__mobile-dots .red-desktop {
    background-color: #e52528;
    top: 420px;
    left: 12%;
  }
  .header__mobile-dots .orange-desktop {
    background-color: #f18918;
    top: 170px;
    left: 2%;
  }
  .header__mobile-dots .red-dot {
    top: 20%;
    right: 43%;
  }
  .header__mobile-dots .red-second-desktop {
    background-color: #e52528;
    top: 35%;
    left: 60%;
  }
  .header__mobile-dots .purple-third-desktop {
    background-color: #9a40ca;
    top: 430px;
    right: 50%;
  }
  .header__mobile-dots .orange-second-dot {
    display: none;
  }
  .header__icon {
    top: 17%;
  }
}
@media (min-width: 1200px) {
  .header__heading {
    font-size: 5rem;
    max-width: 580px;
    line-height: 1;
    margin-top: 150px;
  }
  .header .cyan-desktop {
    right: 30%;
    top: 450px;
    background-color: #f18918;
  }
}
@media (min-width: 1600px) {
  .header__heading {
    font-size: 5rem;
    max-width: 580px;
    line-height: 1;
    margin-top: 150px;
  }
  .header__stats-stat {
    margin: 5.5em;
  }
  .header__icon {
    top: 25%;
  }
  .header .header__items {
    margin-top: 220px;
  }
  .header .bold {
    margin-top: 0.5em;
    font-size: 4.5rem;
    margin-bottom: 0.2em;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .header .header__heading {
    font-size: 7rem;
    max-width: 580px;
    line-height: 1;
    margin-top: 150px;
  }
  .header .red-dot {
    top: 18%;
  }
  .header .dark-purple-dot {
    top: 270px;
  }
  .header .purple-desktop {
    left: 28%;
  }
}
.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0.5em 1em;
}
.nav__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.nav__link {
  transition: opacity 0.3s;
}
.nav__link:hover {
  opacity: 0.6;
}
.nav__logo img {
  height: 75px;
  position: absolute;
  top: 30px;
  cursor: pointer;
}
.nav__links {
  position: absolute;
  top: 80px;
  left: 1em;
  right: 1em;
  display: flex;
  flex-direction: column;
  z-index: 20;
  font-size: 1.8rem;
  transform: translateX(140%);
  transition: transform 0.3s;
  background-color: #0085ff !important;
  border-radius: 24px;
  border-top-right-radius: 0;
  display: none;
  opacity: 0;
}
.nav__links a {
  padding: 0.5em;
  padding-left: 10%;
  padding-bottom: 0.7em;
  margin-bottom: 0.3em;
  color: #fff;
  font-size: 1.6rem;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5333333333);
}
.nav__links a:first-of-type {
  padding-top: 1em;
}
.nav__links a:last-of-type {
  border-bottom: none;
}
.nav .language-mobile {
  position: absolute;
  top: 20px;
  right: 95px;
}
.nav .language-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}
.nav .language-content img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 0.35em;
}
.nav .language-content p {
  margin-right: 0.35em;
  font-size: 1.8rem;
}
.nav .lang-option {
  display: flex;
  font-size: 1.4rem;
  cursor: pointer;
  width: 100%;
  align-items: center;
  z-index: 20;
}
.nav .lang-option:nth-of-type(2) {
  margin-top: 0.7em;
}
.nav .lang-option img {
  pointer-events: none;
}
.nav .lang-option p {
  pointer-events: none;
  font-size: 1.4rem;
  font-weight: bold;
}
.nav .lang-option svg {
  pointer-events: none;
}
.nav .lang-second {
  display: none;
}

.active {
  display: flex;
  transform: translateX(0);
  animation: show 0.3s forwards;
}

@media (min-width: 992px) {
  .burger-btn {
    display: none;
  }
  .nav__links {
    transform: translateX(0);
    width: auto;
    height: auto;
    flex-direction: row;
    top: 0;
    position: static;
    display: flex;
    opacity: 1;
  }
  .nav__links a {
    padding: 1em;
    padding-bottom: 0;
    margin-top: 2.7em !important;
    font-size: 1.6rem;
    border-bottom: none;
    margin: 0 1em;
  }
  .nav__logo img {
    position: static;
    padding-top: 0.6em;
    margin-top: 1em;
  }
  .nav .language-mobile {
    top: 0;
    right: 1.5em;
  }
  .nav .language-content img {
    margin-right: 0.55em;
    width: 20px;
    height: 20px;
  }
  .nav .language-content p {
    margin-right: 0.5em;
  }
  .nav__items {
    position: relative;
  }
  .nav {
    padding-top: 1em;
    padding-bottom: 0;
    padding-left: 2em;
    padding-right: 2em;
  }
  .nav__links {
    background-color: transparent !important;
  }
  .section-padding {
    padding-left: 2em;
    padding-right: 2em;
  }
  .language-mobile.opened {
    background-color: #0084ff;
    padding: 0.8em;
    border-radius: 15px;
    z-index: 20;
    top: -5px;
  }
}
.dts {
  left: 45%;
}

.blue__section {
  position: relative;
}

.how {
  position: relative;
}
.how__bg {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../dist//img/mobile-trusted.png");
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center;
  background-size: cover;
}
.how h2 {
  margin-bottom: 1.5em;
}
.how__icon {
  display: flex;
  margin-bottom: 3.5em;
}
.how__icon-main {
  position: relative;
}
.how img {
  position: absolute;
  bottom: -25px;
  right: 50%;
  transform: translate(50%, 50%) rotate(90deg) scale(1.8);
}
.how__desc {
  max-width: 200px;
  margin-left: 2em;
  line-height: 27px;
  font-size: 15px;
}
.how__heading {
  margin-bottom: 0.5em;
  transition: color 0.3s;
}
.how .icon__circle {
  width: 90px;
  height: 90px;
  position: relative;
  background-color: #0085ff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}
.how .icon__circle svg {
  pointer-events: none;
}
.how .icon__circle path {
  pointer-events: none;
}
.how .second {
  background-color: #fff;
}
.how .second:hover {
  background-color: #0085ff;
}
.how .second:hover path {
  fill: #fff;
}
.how svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.how path {
  transition: fill 0.3s;
}
.how__heading {
  font-weight: bold;
}
.how__img {
  display: none;
}

@media (min-width: 768px) {
  .how__content {
    max-width: 1200px;
  }
  .how__heading {
    margin-top: 1.3em;
    margin-bottom: 0.5em;
  }
  .how__icons {
    display: flex;
    flex-wrap: wrap;
  }
  .how__icon {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    width: 16.6%;
    max-width: 250px;
  }
  .how__desc {
    margin-left: 0;
    font-size: 14px;
    max-width: 120px;
  }
  .how img {
    top: 50%;
    left: 85%;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  .how .icon__circle {
    width: 90px;
    height: 90px;
    position: relative;
    background-color: #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .how img {
    left: 70%;
    transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
  }
  .how__icon {
    width: 16.6%;
    max-width: 250px;
  }
}
@media (min-width: 992px) {
  .how__bg {
    background-image: url("../../dist/img/trusted-desktop.png");
    background-position: center;
  }
  .how__img {
    display: block;
    position: relative;
  }
  .how__img img {
    position: static;
    transform: none;
  }
  .how .img-container {
    position: absolute;
    left: 50%;
    top: -260px;
  }
  .how .small {
    position: absolute;
    right: -10%;
    bottom: 0%;
    top: initial;
    left: initial;
  }
  .how .dotty {
    top: initial;
    left: initial;
    position: absolute;
    bottom: 40px;
    left: -50px;
  }
  .how img {
    left: 75%;
    transform: translate(-50%, -50%) rotate(0deg) scale(1.8);
  }
  .how__img {
    transform: none !important;
  }
  .how__img img {
    transform: none !important;
  }
  .how__desc {
    max-width: 160px;
  }
}
@media (min-width: 768px) {
  .how__heading {
    margin-top: 1.3em;
    margin-bottom: 1em;
    font-weight: bold;
  }
  .how h2 {
    margin-bottom: 2.5em;
  }
}
.form-message {
  display: none;
}

.services {
  position: relative;
}
.services-blue-mobile {
  position: absolute;
  right: 30%;
  top: 60px;
}
.services__icon {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}
.services__blue {
  color: #0085ff;
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 0.9em;
}
.services__desc {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 1.2em;
}
.services__list {
  margin-bottom: 0.5em;
}
.services__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
  line-height: 27px;
}
.services__list-item p {
  margin-left: 1em;
  font-size: 15px;
}
.services .btn {
  align-self: flex-end;
}
.services__btn {
  color: #fff;
  padding: 0.4em 0.8em;
  border-radius: 20px;
  background-color: #0085ff;
  align-self: flex-end;
}
.services__service {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 450px;
}
.services__figure {
  position: relative;
  margin-left: 30%;
  display: none;
  width: 256px;
  height: 256px;
}
.services__figure-orange-dot {
  position: absolute;
  left: 3%;
  top: 10%;
}
.services__figure-dark-dot {
  position: absolute;
  right: -15%;
  top: -5%;
}
.services__figure-small-dot {
  position: absolute;
  right: -7%;
  bottom: 3%;
}

@media (min-width: 992px) {
  .services.section-padding {
    padding-top: 0.7em;
  }
  .services__btn {
    padding: 0.6em 0.8em;
  }
  .services__blue {
    margin-bottom: 1em;
  }
  .services__heading {
    margin-top: -1em;
    font-size: 3.5rem;
    margin-bottom: 0.5em;
  }
  .services__props {
    display: flex;
    flex-wrap: wrap;
  }
  .services__list-item {
    margin-bottom: 0.9em;
  }
  .services__service {
    display: flex;
    max-width: 510px;
  }
  .services__service:nth-of-type(2) {
    margin-left: 5vw;
  }
  .services__icon {
    margin: 1em 0;
  }
  .services .btn {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
  }
  .services-blue-mobile {
    display: none;
  }
  .services__figure {
    display: block;
  }
  .services__figure-light-dot {
    position: absolute;
    right: -100%;
    top: 10%;
  }
  #services h2 {
    margin-top: -1em;
    font-size: 4.2rem;
    margin-bottom: 0.5em;
  }
  .services__heading {
    font-size: 4.2rem;
    margin-bottom: 0.5em;
  }
  .services__blue {
    margin-bottom: 2em;
  }
  .services__list-item {
    margin-bottom: 0.9em;
  }
  .services__desc {
    line-height: 27px;
    margin-bottom: 2.5em;
  }
}
@media (min-width: 1200px) {
  .services__service:nth-of-type(2) {
    margin-left: 10vw;
  }
  .glide {
    margin-bottom: 21em;
  }
  .how h2 {
    margin-top: -1.5em;
  }
  .how .img-container {
    position: absolute;
    left: 50%;
    top: -320px;
  }
  .how__content {
    max-width: 1400px;
  }
  .how__icon {
    width: 16.6%;
    max-width: 250px;
  }
  .how .third {
    display: block;
  }
  .how__desc {
    max-width: 150px;
    font-size: 15px !important;
  }
  .services__heading {
    margin-top: -1em;
    font-size: 4.2rem;
    margin-bottom: 0.5em;
  }
}
@media (max-width: 992px) {
  .services.section-padding {
    padding-top: 5em;
  }
}
@media (min-width: 992px) {
  .services__list-item {
    margin-bottom: 0.9em;
    font-size: 15px;
  }
}
.services__desc {
  margin-bottom: 1.2em;
  font-size: 15px;
}

.index-de .stack__subsection {
  width: 100%;
}

@media (max-width: 678px) {
  .index-de .stack.section-padding {
    padding-right: 0;
  }
}
.stack {
  background-color: #00173a;
}
.stack h2 {
  margin-bottom: 1.3em;
}
.stack__heading {
  font-size: 2rem;
  color: #0085ff;
  margin-bottom: 1em;
  font-weight: bold;
  max-width: 150px;
}
.stack__subsection {
  margin-bottom: 2em;
  width: 50%;
}
.stack .all-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stack__icon {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 0.8em;
}
.stack .cyan-icon {
  background-color: #94cdb4;
}
.stack .red-icon {
  background-color: #e52528;
}
.stack .purple-icon {
  background-color: #7e4d99;
}
.stack .orange-icon {
  background-color: #f18918;
}
.stack__element {
  display: flex;
  align-items: center;
  margin-bottom: 1.3em;
}

@media (min-width: 420px) {
  .index-de .stack__subsection {
    width: 50%;
  }
}
@media (min-width: 576px) {
  .index-de .stack__subsection {
    width: 33%;
  }
}
@media (min-width: 992px) {
  .index-de .stack__subsection {
    width: max-content;
  }
}
@media (min-width: 576px) {
  .stack__subsection {
    width: 33%;
  }
}
@media (min-width: 678px) {
  .orange-dot {
    display: none;
  }
}
@media (min-width: 992px) {
  .stack__subsection {
    width: max-content;
  }
  .stack__heading {
    max-width: 220px;
  }
  .stack__heading {
    font-size: 2rem;
    color: #0085ff;
    margin-bottom: 2em;
    font-weight: 700;
  }
  .glide {
    margin-bottom: 16em;
  }
  .stack h2 {
    margin-bottom: 1.3em;
    padding-top: 30px;
  }
}
.stack__heading {
  font-size: 1.6rem;
  color: #0085ff;
  margin-bottom: 2em;
  font-weight: 700;
  max-width: 300px;
}

.stack__element {
  display: flex;
  align-items: center;
  margin-bottom: 1.7em;
  font-size: 15px;
}

.standards__item {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  margin-bottom: 2em;
}
.standards__desc {
  max-width: 250px;
  margin-left: 1.5em;
}
.standards__descr {
  line-height: 27px;
}
.standards__heading {
  font-weight: bold;
  color: #0085ff;
  margin-top: 0.8em;
  margin-bottom: 1em;
}

@media (min-width: 1080px) {
  #standards h2 {
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
.standards__descr {
  line-height: 31px;
  font-size: 15px;
}

@media (min-width: 992px) {
  .tas .software__centered {
    text-align: left !important;
  }
  .tas .software-sec__texts {
    justify-content: flex-start;
    line-height: 27px;
  }
  .tas .software-sec__text {
    width: 50%;
    max-width: 520px;
  }
  .tas .software-sec__text:first-of-type {
    margin-right: 15%;
  }
  .tas .desktop__bg {
    background-image: url("../../dist/img/dots/taas-bg.jpg");
    left: -1px;
  }
  .tas .desktop__dots-services {
    display: block;
  }
  .tas .desktop__dots-services img:first-of-type {
    position: absolute;
    right: 48%;
    top: 65vh;
  }
  .tas .desktop__dots-services img:nth-of-type(2) {
    position: absolute;
    right: 50%;
    top: 35%;
  }
  .tas .desktop__dots-services img:nth-of-type(3) {
    position: absolute;
    right: 20%;
    top: 55%;
  }
  .tas .desktop__dots-services {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .tas .desktop__bg {
    height: 130vh;
  }
  .tas .software__centered {
    margin-top: 3em;
    margin-bottom: 11em;
    line-height: 27px;
  }
  .tas .how {
    margin-bottom: 4em;
  }
  .tas .services__service {
    margin-bottom: 5em;
  }
  .tas .software-sec__texts {
    line-height: 27px;
  }
}
main .contact.section-padding {
  padding-top: 0;
}

main .contact:before {
  top: 0;
}

.sft-link {
  font-weight: bold;
  color: #0085ff;
}

@media (min-width: 1400px) {
  .tas .desktop__dots-services {
    display: block;
  }
  .tas .desktop__dots-services img:first-of-type {
    position: absolute;
    right: 48%;
    top: 55vh;
  }
  .tas .desktop__dots-services img:nth-of-type(2) {
    position: absolute;
    right: 50%;
    top: 35%;
  }
  .tas .desktop__dots-services img:nth-of-type(3) {
    position: absolute;
    right: 20%;
    top: 55%;
  }
}
.trusted h2 {
  margin-bottom: 1.3em;
}

@media (min-width: 1080px) {
  .trusted.section-padding {
    padding-top: 8em !important;
  }
}
.grecaptcha-badge {
  visibility: hidden !important;
}

.deutsch-heading {
  font-size: 3.6rem !important;
}

@media (min-width: 1080px) {
  .deutsch-heading {
    font-size: 6.5rem !important;
  }
  .stack.section-padding {
    padding-top: 5em;
  }
  .standards__item {
    position: relative;
  }
  .desktop__dot {
    display: block;
    position: absolute;
  }
  .desktop__red {
    left: -15%;
    top: 20%;
  }
  .desktop__purple {
    left: -20%;
    top: 70%;
  }
  .desktop__orange-second {
    right: -35%;
    top: -17%;
  }
  .desktop__green {
    right: -70%;
    top: 0%;
  }
  .desktop__orange {
    top: 80%;
    right: 20%;
  }
  .trusted.section-padding {
    padding-top: 5em;
  }
  #standards h2 {
    margin-bottom: 2em;
  }
  .how.section-padding {
    padding-bottom: 8em;
  }
  .standards {
    position: relative;
  }
  .standards .second-row .standards__item:nth-of-type(1) {
    margin-left: 7%;
  }
  .standards__descr {
    line-height: 31px;
  }
  .standards__content {
    display: none;
  }
  .standards__desktop {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  .standards__heading {
    margin-top: 1.3em;
  }
  .standards__row {
    display: flex;
    margin-bottom: 2.5em;
  }
  .standards__item {
    flex-direction: column;
    width: 33%;
  }
  .standards__desc {
    margin-left: 0;
    max-width: 300px;
  }
  .desktop__img {
    display: block;
    position: absolute;
    right: 22%;
    top: -17%;
  }
  .dot-2 {
    position: absolute;
    left: 3%;
    top: 12%;
  }
}
.footer {
  background-color: #00173a;
  padding: 2em;
}

.foooter__p {
  color: #0085ff;
  font-weight: bold;
}

@media (min-width: 1400px) {
  .desktop__img {
    right: 27%;
  }
}
@media (min-width: 2000px) {
  .desktop__img {
    right: 30%;
  }
}
@media (min-width: 2800px) {
  .desktop__img {
    right: 33%;
  }
}
@media (min-width: 3500px) {
  .desktop__img {
    right: 39%;
  }
}
@media (min-width: 992px) {
  .services__blue {
    margin-bottom: 2em;
  }
  .services__list-item {
    margin-bottom: 0.5em;
  }
}
.services__desc {
  line-height: 27px;
  margin-bottom: 2.5em;
}

.services__btn {
  color: #fff;
  padding: 0.6em 1em;
  border-radius: 20px;
  background-color: #0085ff;
  font-size: 15px;
}

.header__btn {
  color: #fff;
  background-color: #0085ff;
  padding: 0.68em 1.4em;
  border-radius: 20px;
  font-size: 1.43rem;
  z-index: 3;
  max-width: none;
  transition: transform 0.3s;
  display: inline-block;
}

.services__btn {
  color: #fff;
  padding: 0.68em 1.4em;
  border-radius: 20px;
  background-color: #0085ff;
  font-size: 1.43rem;
}

.contact__btn {
  padding: 0.63em 1.4em;
  font-size: 1.42rem;
  background-color: #0076e8;
  border-radius: 20px;
  color: #fff;
  margin-bottom: 1.5em;
  border: none;
  white-space: nowrap;
  transition: transform 0.3s;
}

.contact__btn {
  position: relative;
}