.standards {
	&__item {
		display: flex;
		flex-shrink: 0;
		align-items: flex-start;
		margin-bottom: 2em;
	}
	&__desc {
		max-width: 250px;
		margin-left: 1.5em;
	}
	&__descr {
		line-height: 27px;
	}

	&__heading {
		font-weight: bold;
		color: #0085ff;
		margin-top: 0.8em;
		margin-bottom: 1em;
	}
}
@media (min-width: 1080px) {
	#standards h2 {
		margin-bottom: 2em;
		margin-top: 1em;
	}
}
.standards__descr {
	line-height: 31px;
	font-size: 15px;
}
