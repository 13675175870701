.footer{
    padding-left: 1em !important;
    
    font-size: 15px;
}
@media (min-width: 992px) {
    
    .footer {
        padding: 2.5em 3em !important;
        padding-left: 2.2em !important;
	}
}
