.standards__desktop {
	display: none;
}
.desktop__dot {
	display: none;
}
.desktop__img {
	display: none;
}

.contact__icon-desktop {
	display: none;
}

.contact.section-padding {
	padding-top: 4.5em;
}
.contact {
	position: relative;
	a {
		color: #fff;
	}

	.tel {
		text-decoration: underline;
	}
	.mail {
		text-decoration: underline;
		margin-bottom: 0.5em;
	}

	&__icon-mobile {
		position: absolute;
		right: 0;
		top: 0;
		z-index: -2;
	}
	h2 {
		margin-bottom: 1em;
	}
	&__desc {
		line-height: 30px;
	}
	&__icons {
		margin-top: 1.5em;
		margin-bottom: 1.5em;
		img {
			margin-right: 0.25em;
			width: 32px;
		}
	}
}
.contact {
	&__div {
		position: relative;
		min-height: 38px;
		min-width: 200px;
		width: 100%;
		max-width: 400px;
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
	}

	&__input {
		width: 90%;
		font-size: 1.5rem;
		height: 100%;
		border: 1px solid rgb(255, 255, 255);
		border-radius: 5px;
		outline: none;
		background-color: #fff;
		padding: 0.5em 1em;
		z-index: 1;
		max-width: 400px;
	}
	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 1em;
	}
	.textarea {
		min-height: 140px;
		line-height: 25px;
		resize: vertical;
		width: 100%;
	}
	&__dots {
		display: none;
	}

	&__label {
		position: absolute;
		left: 1rem;
		z-index: 23;
		top: 1rem;
		font-size: 1.5rem;
		padding: 0 0.25rem;
		background-color: #ffffff;
		color: #000000;

		transition: top 0.3s, left 0.3s, font-size 0.3s;
	}
	&__btn {
		padding: 0.8em 0.1em;
		font-size: 1.5rem;
		background-color: #0076e8;
		border-radius: 20px;
		color: #fff;
		width: 80%;
		max-width: max-content;
		margin-bottom: 1.5em;
		cursor: pointer;
		border: none;
		transition: transform 0.3s;
		&:hover {
			transform: scale(0.98);
		}
	}
}
.lang-visible {
	display: flex !important;
}
@media (min-width: 992px) {
	.contact.section-padding {
		padding-top: 7.5em;
	}

	.contact {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 85px;
			bottom: 0;
			width: 100%;
			height: 100%;
			left: 0;
			background-image: url('../../dist/img/contact-bg.png');
			background-size: cover;
			background-position: center;
			background-size: 100%, auto, cover;
			background-repeat: no-repeat;
			background-position: top center;
			z-index: -1;
		}
		.wrapper {
			background-size: cover;
			background-position: center;
		}
		&__icon-mobile {
			display: none;
		}
		&__icon-desktop {
			display: block;
			position: absolute;
			left: 50%;
			top: 15%;
		}
		&__desc {
			margin-bottom: 1.5em;
		}
		&__icons {
			margin-bottom: 2em;
			img {
				margin-right: 0.4em;
			}
		}
		&__dots {
			display: block;
		}
		.orange-contact {
			position: absolute;
			top: 70%;
			right: 20%;
			z-index: 2;
		}
		.contact__dots {
			display: block;
		}
		.contact__desktop .dot {
			width: 5px;
			position: absolute;
			height: 5px;
			z-index: -2;
			border-radius: 50%;
			animation: blink 3s infinite forwards;
		}
		.contact-red {
			background-color: #e52528;
			top: 25%;
			right: 20%;
			z-index: 3;
			animation-delay: 0.9s !important;
		}
		.contact-cyan {
			background-color: #94cdb4;
			top: 65%;
			left: 50%;
			animation-delay: 0.7s !important;
		}
		.contact-purple {
			background-color: #7e4d99;
			right: 14%;
			top: 50%;
			animation-delay: 0.1s !important;
		}
		.contact-orange {
			background-color: #f18918;
			top: 80%;
			left: 34%;
			animation-delay: 0.2s !important;
		}
		.contact-orange-second {
			background-color: #f18918;
			top: 15%;
			left: 40%;
			animation-delay: 0.5s !important;
		}
		h2 {
			padding-top: 1.5em;
		}
	}
}
.contact__desc {
	line-height: 30px;
	font-size: 15px;
}

@media (min-width: 1600px) {
	.contact {
		.contact-red {
			top: 30%;
		}
	}
}
.has-error {
	border-color: #ed1c24;
}
.error-div {
	width: 90%;
	background: #ed1c24;
	color: #fff;
	font-size: 14px;
	padding: 7px;
	margin-top: 0;
	margin-bottom: 0.5em;
	max-width: 400px;
	border-radius: 5px;
}
.contact__div.textarea {
	.error-div {
		width: 100%;
	}
}
.contact__btn[disabled] {
	background-color: rgba(226, 226, 226, 0.5);
	cursor: not-allowed;
}
.contact__btn {
	cursor: pointer;
}

.error-hidden {
	display: none;
}
.error-visible {
	display: block;
}
