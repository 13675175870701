.nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	padding: 0.5em 1em;
	&__items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
	}
	&__link {
		transition: opacity 0.3s;
		&:hover {
			opacity: 0.6;
		}
	}
	&__logo img {
		height: 75px;
		position: absolute;
		top: 30px;

		cursor: pointer;
	}

	&__links {
		position: absolute;
		top: 80px;
		left: 1em;
		right: 1em;
		display: flex;
		flex-direction: column;
		z-index: 20;
		font-size: 1.8rem;
		transform: translateX(140%);
		transition: transform 0.3s;
		background-color: #0085ff !important;
		border-radius: 24px;
		border-top-right-radius: 0;
		display: none;
		opacity: 0;
		a {
			padding: 0.5em;
			padding-left: 10%;
			padding-bottom: 0.7em;
			margin-bottom: 0.3em;
			color: #fff;
			font-size: 1.6rem;
			border-bottom: 1px solid #dddddd88;
			&:first-of-type {
				padding-top: 1em;
			}
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
	.language-mobile {
		position: absolute;
		top: 20px;
		right: 95px;
	}
	.language-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
		img {
			border-radius: 50%;
			width: 25px;
			height: 25px;
			margin-right: 0.35em;
		}
		p {
			margin-right: 0.35em;
			font-size: 1.8rem;
		}
	}

	.lang-option {
		display: flex;
		font-size: 1.4rem;
		cursor: pointer;
		width: 100%;
		align-items: center;
		z-index: 20;
		&:nth-of-type(2) {
			margin-top: 0.7em;
		}
		img {
			pointer-events: none;
		}
		p {
			pointer-events: none;
			font-size: 1.4rem;
			font-weight: bold;
		}
		svg {
			pointer-events: none;
		}
	}
	.lang-second {
		display: none;
	}
}
.active {
	display: flex;
	transform: translateX(0);
	animation: show 0.3s forwards;
}

@media (min-width: 992px) {
	.burger-btn {
		display: none;
	}
	.nav {
		&__links {
			transform: translateX(0);
			width: auto;
			height: auto;
			flex-direction: row;
			top: 0;
			position: static;
			display: flex;
			opacity: 1;
			a {
				padding: 1em;
				padding-bottom: 0;
				margin-top: 2.7em !important;
				font-size: 1.6rem;
				border-bottom: none;
				margin: 0 1em;
			}
		}
		&__logo img {
			position: static;
			padding-top: 0.6em;
			margin-top: 1em;
		}
		.language-mobile {
			top: 0;
			right: 1.5em;
		}
		.language-content {
			img {
				margin-right: 0.55em;
				width: 20px;
				height: 20px;
			}
			p {
				margin-right: 0.5em;
			}
		}
		&__items {
			position: relative;
		}
	}
	.nav {
		padding-top: 1em;
		padding-bottom: 0;
		padding-left: 2em;
		padding-right: 2em;

		&__links {
			background-color: transparent !important;
		}
	}
	.section-padding{
		padding-left: 2em;
		padding-right: 2em;
	}
	.language-mobile.opened {
		background-color: #0084ff;
		padding: 0.8em;
		border-radius: 15px;
		z-index: 20;
		top: -5px;
	}
}
