.fixed-page {
	overflow: hidden;
}

.wrapper {
	margin: 0 auto;
	max-width: 1400px;
	width: 100%;
}

.burger-btn {
	padding: 0.5em;
	background: none;
	border: none;
	cursor: pointer;
	margin-top: 0;
	position: relative;
	&__box {
		width: 45px;
		height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	&-line {
		height: 4px;
		width: 100%;
		background-color: #ffffff;
		transition: transform 0.3s, opacity 0.2s;
	}
}
.opened.burger-btn-line--1 {
	transform: rotate(45deg) translate(9px, 10px);
}
.opened.burger-btn-line--2 {
	opacity: 0;
}
.opened.burger-btn-line--3 {
	transform: rotate(-45deg) translate(9px, -10px);
}
.section-padding {
	padding: 4em 1em;
}
.header.section-padding {
	padding-bottom: 0;
}

.services.section-padding {
	padding-top: 2em;
}
.desktop__dots-services {
	display: none;
}
.services__heading {
	font-size: 2.7rem;
}

.glide {
	width: 100%;
	max-width: 250px;
	display: flex;
	justify-content: center;

	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.glide__arrow--left {
	background-image: url('../../dist/img/icons/arrow-left.png');
	background-color: transparent;
	display: flex;
	position: absolute;
	left: -9%;
	top: 45%;
	background-size: cover;
	width: 15px;
	height: 22px;
	background-repeat: no-repeat;
	&::after {
		content: none;
	}
}
.glide__arrow--right {
	background-image: url('../../dist/img/icons/arrow-right.svg');
	display: flex;
	background-size: cover;
	width: 15px;
	background-color: transparent;
	height: 22px;
	position: absolute;
	right: -6%;
	top: 45%;
	background-repeat: no-repeat;
}

.hovered {
	background-color: #fff !important;
	path {
		fill: #0085ff !important;
	}
}
.howered-heading {
	color: #0085ff !important;
}
@media (min-width: 700px) {
	.glide {
		max-width: 600px;
	}
}
@media (min-width: 1024px) {
	.glide {
		max-width: 900px;
	}
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.contact__btn {
	position: relative;
	min-height: 42px;
}
#loading-bar-spinner.spinner {
	left: 50%;
	margin-left: -20px;
	top: 50%;
	margin-top: -15px;
	position: absolute;
	z-index: 19 !important;
	animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
	width: 26px;
	height: 26px;
	border: solid 4px transparent;
	border-top-color: #ffffff !important;
	border-left-color: #ffffff !important;
	border-radius: 50%;
}

@keyframes loading-bar-spinner {
	0% {
		transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
