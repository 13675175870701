.header {
	&__mobile-bg {
		position: absolute;
		top: 60px;
		left: 0;
		height: 100vh;
		width: 100%;
		background-image: url('../../dist/img/header-img.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		z-index: -1;
	}
	&__items {
		margin-top: 80px;
	}
	&__heading {
		max-width: 450px;
		font-size: 3.1rem;
		line-height: 40px;
		margin-bottom: 0.7em;
	}
	&__subtitle {
		font-size: 1.5rem;
		line-height: 25px;
		margin-bottom: 2em;
		max-width: 500px;
	}
	&__btn {
		color: #fff;
		background-color: #0085ff;
		padding: 0.6em 1.3em;
		border-radius: 20px;
		font-size: 1.6rem;
		z-index: 3;
		transition: transform 0.3s;
		display: inline-block;
		&:hover {
			// background-color: #fff;
			transform: scale(0.98);
		}
	}
	.bold {
		margin-top: 0.5em;
		font-size: 3.5rem;
		margin-bottom: 0.2em;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
	&__stats {
		margin-top: 3em;
		display: flex;
		justify-content: center;
		position: relative;
		flex-wrap: wrap;
	}
	&__stats-stat {
		margin: 2em 1.5em;
		width: 100%;
		min-width: 165px;
		max-width: max-content;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.desc {
		font-size: 1.6rem;
	}
	&__icon {
		display: none;
	}

	.orange-dot {
		position: absolute;
		left: -5%;
		bottom: 30%;
	}
	&__mobile-dots {
		.purple-dot {
			background-color: #7e4d99;
			left: 30%;
			top: 100px;
		}

		.red-dot {
			background-color: #e52528;
			top: 120px;
			right: 30%;
		}
		.dark-purple-dot {
			background-color: #7e4d99;
			right: 10%;
			top: 290px;
		}
		.cyan-dot {
			background-color: #94cdb4;
			right: 25%;
			top: 280px;
		}
		.second-red-dot {
			background-color: #e52528;
			left: 15%;
			top: 270px;
		}
		.orange-second-dot {
			background-color: #f18918;
			top: 430px;
			right: 45%;
		}

		.dot {
			width: 5px;
			position: absolute;

			height: 5px;
			z-index: -2;
			border-radius: 50%;
			animation: blink 3s infinite forwards;
			&:nth-of-type(1) {
				animation-delay: 0.4s;
			}
			&:nth-of-type(2) {
				animation-delay: 0.6s;
			}
			&:nth-of-type(3) {
				animation-delay: 0.3s;
			}
			&:nth-of-type(4) {
				animation-delay: 0.7s;
			}
			&:nth-of-type(5) {
				animation-delay: 0.6s;
			}
			&:nth-of-type(6) {
				animation-delay: 0.5s;
			}
			&:nth-of-type(7) {
				animation-delay: 0.9s;
			}
			&:nth-of-type(9) {
				animation-delay: 0.4s;
			}
			&:nth-of-type(9) {
				animation-delay: 0.5s;
			}
			&:nth-of-type(10) {
				animation-delay: 0.9s;
			}

			&:nth-of-type(10) {
				animation-delay: 0.7s;
			}
		}
	}
}

// @media (min-width: 450px) {
// 	.header {
// 		&__stats-stat {
// 			margin: 2em 1.5em;
// 			width: 40%;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: center;
// 			align-items: center;
// 		}
// 	}
// }

@media (min-width: 992px) {
	.header {
		min-height: 100vh;
		.header__heading {
			font-size: 4rem;
			max-width: 580px;
			line-height: 1;
			margin-top: 110px;
		}

		&__mobile-bg {
			background-image: url('../../dist/img/header-big.png');
			top: 90px;
		}
		&__icon {
			display: block;
			position: absolute;
			top: 25%;
			right: 0;
		}
		&__stats {
			margin-top: 0em;
		}
		&__stats-stat {
			margin: 4em;
			margin-bottom: 4em;
		}
		&__mobile-dots {
			.cyan-desktop {
				background-color: #94cdb4;
				right: 43%;
				top: 380px;
			}
			.purple-desktop {
				background-color: #7e4d99;
				top: 80%;
				left: 25%;
			}
			.red-desktop {
				background-color: #e52528;
				top: 420px;
				left: 12%;
			}
			.orange-desktop {
				background-color: #f18918;
				top: 170px;
				left: 2%;
			}
			.red-dot {
				top: 20%;
				right: 43%;
			}
			.red-second-desktop {
				background-color: #e52528;
				top: 35%;
				left: 60%;
			}
			.purple-third-desktop {
				background-color: #9a40ca;
				top: 430px;
				right: 50%;
			}
			.orange-second-dot {
				display: none;
			}
		}
		&__icon {
			top: 17%;
		}
	}
}

@media (min-width: 1200px) {
	.header__heading {
		font-size: 5rem;
		max-width: 580px;
		line-height: 1;
		margin-top: 150px;
	}
	.header {
		.cyan-desktop {
			right: 30%;
			top: 450px;
			background-color: #f18918;
		}
	}
}
@media (min-width: 1600px) {
	.header__heading {
		font-size: 5rem;
		max-width: 580px;
		line-height: 1;
		margin-top: 150px;
	}
	.header {
		&__stats-stat {
			margin: 5.5em;
		}
		&__icon {
			top: 25%;
		}
		.header__items {
			margin-top: 220px;
		}
		.bold {
			margin-top: 0.5em;
			font-size: 4.5rem;
			margin-bottom: 0.2em;
			font-weight: 700;
			display: flex;
			align-items: center;
		}
		.header__heading {
			font-size: 7rem;
			max-width: 580px;
			line-height: 1;
			margin-top: 150px;
		}
		.red-dot {
			top: 18%;
		}
		.dark-purple-dot {
			top: 270px;
		}
		.purple-desktop {
			left: 28%;
		}
	}
}
