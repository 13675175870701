main {
	.software-sec.section-padding {
		padding-bottom: 2em;
	}
	h1 {
		font-size: 6.5rem !important;
	}
}
.sft {
	.contact.section-padding {
		padding-top: 0;
	}
}
.software-sec {
	.header__btn{
		margin-top: 1.5em;
	}
	.how__img {
		display: none;
	}
	.contact.section-padding {
		padding-top: 0;
	}
	.how.section-padding {
		padding-left: 0em;
		padding-right: 0em;
	}

	margin-top: 4em;
	.services__service {
		margin-left: 0 !important;
		margin-bottom: 3em;
	}
	h1 {
		font-size: 4rem;
		margin-bottom: 0.1em;
	}
	h2 {
		color: #0085ff;
		margin-bottom: 0.5em;
	}
	.services__blue {
		font-size: 1.8rem;
	}
	.services__icon {
		margin-bottom: 1em;
	}

	&__heading {
		color: #0085ff;
		margin-bottom: 1em;
		font-size: 1.6rem;
		font-weight: bold;
	}
	&__texts {
		display: flex;
		font-size: 15px;
		flex-direction: column;
		line-height: 26px;
	}
	&__text {
		margin-bottom: 2.5em;
	}
	.software__centered-heading {
		color: #0085ff;
		margin-bottom: 1.3em;
		font-size: 1.6rem;
		font-weight: bold;
	}
	.software__centered-desc {
		font-size: 15px;
		line-height: 26px;
		margin-bottom: 4em;
	}
	.how {
		h2 {
			color: #fff;
		}
	}
}

@media (min-width: 992px) {
	.dotters {
		height: 100vh;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	.sft {
		.contact::before {
			top: 0;
		}
	}
	.dev {
		.desktop__dots-services.dsk {
			position: relative;
			width: 100%;
			height: 100%;
			z-index: 0;
			display: block;
			img {
				&:first-of-type {
					position: absolute;
					right: 10%;
					top: 80%;
				}
				&:nth-of-type(2) {
					position: absolute;
					right: 25%;
					top: 45%;
				}
				&:nth-of-type(3) {
					position: absolute;
					right: 45%;
					top: 80%;
				}
			}
		}
	}
	.desktop__bg {
		background-image: url('../../dist/img/soft-bg.jpg');
		position: absolute;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 0px -20px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 130vh;
		z-index: -2;
	}

	.software-sec {
		h1 {
			font-size: 5.5rem;
			margin-bottom: 0.05em;
		}
		h2 {
			font-size: 3.5rem;
			margin-bottom: 0.8em;
		}
		&__desc {
			font-size: 1.65rem;
		}
		&__texts {
			margin-top: 4em;
			flex-direction: row;
			justify-content: space-between;
		}
		&__text {
			width: 33%;
			max-width: 400px;
			margin-right: 2em;
			&:last-of-type {
				margin-right: 0;
			}
		}
		.software__centered {
			margin-top: 2em;
			text-align: center;
			margin-bottom: 7em !important;
		}
		.how {
			h2 {
				margin-bottom: 2em;
			}
			margin-bottom: 2em;
		}
	}
	.dev {
		.software__centered {
			text-align: center !important;
			margin-top: 4em;
			margin-bottom: 11em;
			line-height: 27px;
		}
		.how {
			margin-bottom: 4em;
		}
		.services__service {
			margin-bottom: 5em;
		}
		.software-sec__texts {
			line-height: 27px;
		}
	}
}
.form-message {
	background-color: #0085ff;
	max-width: 400px;
	line-height: 27px;
	border-radius: 9px;
	padding: 10px;
	font-size: 15px;
}
.form-message.error-d {
	background-color: #ed1c24;
}
