.trusted {
	h2 {
		margin-bottom: 1.3em;
	}
}

@media (min-width: 1080px) {
	.trusted.section-padding {
		padding-top: 8em !important;
	}
}
